import { skipNativePrompt } from "../app/migrationSlice";
import { hideModal } from "../app/modalSlice";
import { useAppDispatch } from "../hooks";
import Button from "./Button";
import Modal from "./Modal";

export const UseNativeModal = () => {
  const dispatch = useAppDispatch();

  const openApp = () => {
    window.open("com.playhangfive://", "_blank");
  };

  const closeModal = () => {
    dispatch(skipNativePrompt());
    dispatch(hideModal());
  };

  return (
    <Modal
      footer={
        <p className="text-center mt-4 text-sm">
          <a className="underline" href="#" onClick={closeModal}>
            No thanks, I'll play here
          </a>
        </p>
      }
    >
      <h1 className="mb-2 font-bold text-lg">
        You've installed the Hang Five app
      </h1>
      <p className="mb-2">
        We've transferred your stats there, and you can continue playing from
        where you left off.
      </p>
      <div className="mt-4 text-center">
        <Button onClick={openApp}>Open the app</Button>
      </div>
    </Modal>
  );
};
