import { useEffect, useState } from "react";
import Button from "./Button";

type ModalProps = {
  onCloseClick?: () => void;
  children?: React.ReactNode;
  buttonText?: string;
  buttonVariant?: "primary" | "secondary";
  footer?: React.ReactNode;
  buttons?: React.ReactNode;
};

const Modal = ({
  onCloseClick,
  children,
  buttonText,
  buttonVariant,
  footer,
  buttons,
}: ModalProps) => {
  const [shown, setShown] = useState(false);

  useEffect(() => {
    setShown(true);
  }, [setShown]);

  const onContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const handleClose = () => {
    setShown(false);
  };

  function handleTransitionEnd(e: React.TransitionEvent<HTMLDivElement>) {
    if (e.propertyName === "opacity") {
      const { opacity } = getComputedStyle(e.target as Element);
      if (parseFloat(opacity) === 0) {
        if (onCloseClick) {
          onCloseClick();
        }
      }
    }
  }

  return (
    <>
      <div
        className={`fixed inset-0 bg-gray-600 ${
          shown ? "bg-opacity-50" : "bg-opacity-0"
        } overflow-y-auto h-full w-full z-40 transition-all`}
        onClick={handleClose}
      ></div>
      <div
        className={`absolute top-0 w-full h-full z-50 ${
          shown ? "opacity-100" : "opacity-0"
        } transition-all`}
        onClick={handleClose}
        onTransitionEnd={handleTransitionEnd}
      >
        <div className="h-full items-center flex">
          <div className="w-11/12 mx-auto max-w-md">
            <div
              className="p-7 border shadow-lg rounded-md bg-white dark:bg-slate-800 lg:mx-4 text-gray-500 dark:text-slate-400"
              onClick={onContainerClick}
            >
              <div className="text-left">
                <div className="text-sm">{children}</div>
                {(buttons || (buttonText && onCloseClick)) && (
                  <div className="items-center mt-4 -mx-2 flex justify-between">
                    {buttonText && onCloseClick && (
                      <Button
                        id="ok-btn"
                        className="flex-grow mx-2"
                        onClick={handleClose}
                        variant={buttonVariant}
                      >
                        {buttonText}
                      </Button>
                    )}
                    {buttons}
                  </div>
                )}
              </div>
              {footer && <div>{footer}</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export type { ModalProps };
export default Modal;
