import { useEffect } from "react";
import GameContent from "./GameContent";
import { useAppDispatch } from "../hooks";
import { clearToasts } from "../app/toastSlice";
import { setJustFinished } from "../app/gameSlice";

type Props = {
  onKeyPress: (input: string) => void;
  loadNextGame: () => void;
  isLoading: boolean;
};

const GameScreen = ({ onKeyPress, loadNextGame, isLoading }: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearToasts());
      dispatch(setJustFinished(false));
    };
  }, [dispatch]);

  return (
    <GameContent
      onKeyPress={onKeyPress}
      loadNextGame={loadNextGame}
      isLoading={isLoading}
    />
  );
};

export default GameScreen;
