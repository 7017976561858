import posthog from "posthog-js";

import { getVersionNumber, getUid } from "./system";
import { store } from "../app/store";
import { setFeatures } from "../app/featuresSlice";
import { Feature } from "./features";

const multivariateFlags: Feature[] = [];

export const initializeTracking = () => {
  posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
    api_host: "https://us.i.posthog.com",
    person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
  });

  posthog.identify(getUid());

  console.log("e2eFeatureFlagOverride", window.__e2eFeatureFlagOverride);

  const overrideFeatureFlags =
    localStorage.getItem("overrideFeatureFlags") === "1";

  if (
    (window.__e2eFeatureFlagOverride === undefined && overrideFeatureFlags) ||
    window.__e2eFeatureFlagOverride === true
  ) {
    console.log("Overriding feature flags");
    posthog.featureFlags.override({ "end-screen-retention": "test" });
  }

  posthog.onFeatureFlags((featureFlags) => {
    console.log("Feature flags", featureFlags);

    let enabledFlags = featureFlags;

    for (const flag of multivariateFlags) {
      if (posthog.getFeatureFlag(flag) !== "test") {
        enabledFlags = enabledFlags.filter((f) => f !== flag);
      }
    }

    store.dispatch(setFeatures(enabledFlags as Feature[]));
  });

  // Initialize Google Analytics (if not already done)
  // Initialize Facebook Pixel (if needed)
};

export const trackEvent = (
  eventName: string,
  properties: Record<string, unknown>
) => {
  const commonProperties = {
    ...properties,
    appVersion: getVersionNumber(),
    userId: getUid(),
  };

  // TODO: Map eventName to the correct platforms

  console.log("Tracking event", eventName, commonProperties);

  // Send to Posthog
  posthog.capture(eventName, commonProperties);

  // Send to Google Analytics
  gtag("event", eventName, commonProperties);

  // if development mode
  if (import.meta.env.MODE === "development") {
    console.log("Tracking. Is development mode");
    if (window.__e2eTrackEvent) {
      window.__e2eTrackEvent({
        eventName,
        properties: commonProperties,
      });
    }
  }

  // Send to Facebook Pixel (if needed)
  // fbq('track', eventName, commonProperties);

  // You can add conditions here to determine which platforms receive which events
  // For example:
  // if (eventName === 'Purchase') {
  //   fbq('track', eventName, commonProperties);
  // }
};
