import { colours } from "../constants/colours";
import LifeStatus from "../types/LifeStatus";
import HeartIcon from "./icons/HeartIcon";
import { useAppSelector } from "../hooks";
import { useEffect, useState } from "react";
import { selectIsLearning, selectLostLifeReasons } from "../app/gameSlice";
import { logInfo } from "../helpers/tracing";
import { GuideMessage, selectGuideMessage } from "../app/learningSlice";

type Props = {
  className?: string;
};

const getHeartEmojiForStatus = (
  status: LifeStatus,
  pulseFirstVowel: boolean = false
) => {
  switch (status) {
    case LifeStatus.ALIVE:
      return <HeartIcon className="fill-red-600 dark:fill-red-700" />;
    case LifeStatus.VOWEL:
      return (
        <HeartIcon
          className={`${colours.vowel.fill.light} ${colours.vowel.fill.dark} ${
            pulseFirstVowel ? "animate-ping" : ""
          }`}
        />
      );
    default:
      return <HeartIcon className="fill-slate-400 dark:fill-slate-500" />;
  }
};

const LivesIndicator = ({ className }: Props) => {
  logInfo({ message: "Rendering LivesIndicator" });

  const isLearning = useAppSelector(selectIsLearning);
  const guideMessage = useAppSelector(selectGuideMessage);

  const [pulseFirstVowel, setPulseFirstVowel] = useState(false);

  const totalLives = 5;
  const liveStatus: LifeStatus[] = [];

  const lostLifeReasons = useAppSelector(selectLostLifeReasons);

  const remainingLives = totalLives - lostLifeReasons.length;

  for (let i = 0; i < remainingLives; i++) {
    liveStatus.push(LifeStatus.ALIVE);
  }

  const lifeShowLimit =
    isLearning && lostLifeReasons.length > 5 ? lostLifeReasons.length - 5 : 0;

  for (let i = lostLifeReasons.length - 1; i >= lifeShowLimit; i--) {
    liveStatus.push(lostLifeReasons[i]);
  }

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined = undefined;

    if (isLearning && guideMessage === GuideMessage.earlyVowel) {
      setPulseFirstVowel(true);

      timer = setTimeout(() => {
        setPulseFirstVowel(false);
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    } else {
      setPulseFirstVowel(false);

      if (timer) {
        clearTimeout(timer);
      }
    }
  }, [guideMessage, isLearning]);

  return (
    <div className={`lives-indicator ${className} inline-flex space-x-0.5`}>
      {liveStatus.map((status, index) => (
        <span key={index}>
          {getHeartEmojiForStatus(status, pulseFirstVowel)}
        </span>
      ))}
    </div>
  );
};

export default LivesIndicator;
