import { trace } from "@opentelemetry/api";
import Button from "./Button";
import SadFaceIcon from "./icons/SadFaceIcon";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const NotFound = () => {
  const [correlationId, setCorrelationId] = useState<string | undefined>();

  const navigate = useNavigate();

  useEffect(() => {
    const span = trace.getActiveSpan();
    setCorrelationId(span?.spanContext().traceId);
  }, []);

  return (
    <div className="flex w-full h-full items-center">
      <div className="text-center w-full px-5 dark:text-slate-400">
        <SadFaceIcon
          size={90}
          className="fill-gray-400 mx-auto dark:fill-gray-600"
        />
        <h1 className="text-xl font-bold mb-2 mt-5">Page not found!</h1>
        <p>Try heading back to the main game page</p>
        <Button className="my-5" onClick={() => navigate("/")}>
          Back to the game
        </Button>
        <p>
          If it doesn't work, please email us at{" "}
          <a className="underline" href="mailto:hello@playhangfive.com">
            hello@playhangfive.com
          </a>
          {correlationId && <> and mention {correlationId}</>}
        </p>
      </div>
    </div>
  );
};

export default NotFound;
