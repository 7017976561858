const REMAINING_LIFE_ICON = "❤️";
const WRONG_LETTER_ICON = "🖤";
const WON_ICON = "✅";
const LOST_ICON = "❌";
const BOUGHT_VOWEL_ICON = "💙";

export {
  REMAINING_LIFE_ICON,
  WRONG_LETTER_ICON,
  WON_ICON,
  LOST_ICON,
  BOUGHT_VOWEL_ICON,
};
