import Word from "./Word";
import GameState from "../types/GameState";
import { useAppSelector } from "../hooks";
import {
  selectCorrectGuesses,
  selectGameStatus,
  selectIncorrectGuesses,
} from "../app/gameSlice";
import { logInfo } from "../helpers/tracing";

type Props = {
  className?: string;
  isSmall: boolean;
};

const WordGrid = ({ className, isSmall }: Props) => {
  logInfo({ message: "Rendering WordGrid" });

  const { puzzle } = useAppSelector((state) => state.game);
  const gameState = useAppSelector(selectGameStatus);
  const correctGuesses = useAppSelector(selectCorrectGuesses);
  const incorrectGuesses = useAppSelector(selectIncorrectGuesses);

  const { words, gameIndex: gameNumber } = puzzle;

  const letters = [...correctGuesses, ...incorrectGuesses];

  // flex-grow mx-auto w-[21rem] md:w-[24.5rem]

  const maxWordLength = words.reduce(
    (max, word) => Math.max(max, word.length),
    0
  );

  // TODO: Also need to account for narrower screen where the numbers get smaller
  const allWidthClassNames = [
    ["w-[3rem] md:w-[3.5rem]", "w-[2.3125rem] md:w-[2.6875rem]"],
    ["w-[6rem] md:w-[7rem]", "w-[4.625rem] md:w-[5.375rem]"],
    ["w-[9rem] md:w-[10.5rem]", "w-[6.9375rem] md:w-[8.0625rem]"],
    ["w-[12rem] md:w-[14rem]", "w-[9.25rem] md:w-[10.75rem]"],
    ["w-[15rem] md:w-[17.5rem]", "w-[11.5625rem] md:w-[13.4375rem]"],
    ["w-[18rem] md:w-[21rem]", "w-[13.875rem] md:w-[16.125rem]"],
    ["w-[21rem] md:w-[24.5rem]", "w-[16.1875rem] md:w-[18.8125rem]"],
  ];

  const widthClassName =
    maxWordLength > 0 ? allWidthClassNames[maxWordLength - 1] : ["", ""];

  // TODO: Don't even bother showing the grid if there's no words

  return (
    <div
      className={`${className} mx-auto ${
        isSmall ? widthClassName[1] : widthClassName[0]
      }`}
    >
      <div className={`flex flex-col ${isSmall ? "gap-0.5" : "gap-1.5"}`}>
        {words.map((word, index) => (
          <Word
            key={word}
            allWords={words}
            wordIndex={index}
            gameNumber={gameNumber}
            answer={word}
            letters={letters}
            reveal={gameState === GameState.LOST}
            showVowels={true}
            isSmall={isSmall}
          />
        ))}
      </div>
    </div>
  );
};

export default WordGrid;
