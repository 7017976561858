import { postNativeBridgeMessage } from "./nativeBridge";
import { isNative } from "./system";
import { logError } from "./tracing";

enum ShareResult {
  CopiedToClipboard = "copied",
  Shared = "shared",
  Failed = "failed",
  Cancelled = "cancelled",
}

async function share(textToShare: string): Promise<ShareResult> {
  if (isNative()) {
    postNativeBridgeMessage("shareResult", textToShare);
    return ShareResult.Shared;
  }

  const isAndroidOrIOS = navigator.userAgent.match(/Android|iPhone/i);
  const isNotFirefox = !navigator.userAgent.match(/Firefox/i);
  if (navigator.share && isAndroidOrIOS && isNotFirefox) {
    try {
      await navigator.share({
        text: textToShare,
      });
      return ShareResult.Shared;
    } catch (e) {
      if (e instanceof DOMException && e.name === "AbortError") {
        return ShareResult.Cancelled;
      }
      logError(e, { message: "Error sharing natively" });
      return ShareResult.Failed;
    }
  } else {
    if (navigator.clipboard) {
      try {
        navigator.clipboard.writeText(textToShare);
        return ShareResult.CopiedToClipboard;
      } catch (e) {
        logError(e, { message: "Error sharing via clipboard" });
        return ShareResult.Failed;
      }
    }
  }
  return ShareResult.Failed;
}

export { share, ShareResult };
