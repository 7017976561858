type Props = {
  value?: string;
  label: string;
};

export const StatPanel = ({ label, value }: Props) => {
  return (
    <div className="text-center font-normal text-2xl">
      {value ?? "00"}
      <span className="text-xs block">{label}</span>
    </div>
  );
};
