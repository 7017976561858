import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useEffect } from "react";
import {
  beginCompleteMigration,
  selectMigrationRedirectUrl,
  startMigration,
} from "../app/migrationSlice";
import { MigrationPayload } from "../types/MigrationPayload";
import Button from "./Button";

export const MigrateToNative = () => {
  const dispatch = useAppDispatch();

  const { platform } = useParams<{ platform: string }>();

  const redirectUrl = useAppSelector(selectMigrationRedirectUrl);

  const manualRedirect = false;

  useEffect(() => {
    dispatch(startMigration({ platform }));
  }, [dispatch, platform]);

  useEffect(() => {
    if (!manualRedirect && redirectUrl) {
      if (window.__e2eTrackRedirect) {
        window.__e2eTrackRedirect(redirectUrl);
      }
      window.location = redirectUrl as unknown as Location;
    }
  }, [redirectUrl, manualRedirect]);

  const location = useLocation();

  useEffect(() => {
    if (["android", "ios", "ipad"].includes(platform ?? "unknown")) {
      // is there a payload in the fragment?
      let rawPayload = null;

      if (location.hash && location.hash.length) {
        const searchParams = new URLSearchParams(location.hash.slice(1));
        rawPayload = searchParams.get("payload");
      }

      let payload = null;
      if (rawPayload) {
        // decode the payload
        payload = JSON.parse(atob(rawPayload)) as MigrationPayload;

        // do something with the payload
        console.log("Decoded payload", payload);
      }

      if (payload) {
        dispatch(beginCompleteMigration(payload));
      } else {
        dispatch(startMigration({ platform }));
      }
    }
  }, [dispatch, location.hash, platform]);

  return (
    <div className="bg-white dark:bg-slate-900 flex items-center justify-center min-h-screen">
      <div>
        <h1 id="transferring" className="text-xl text-black dark:text-white">
          Transferring your stats...
        </h1>
        {manualRedirect && redirectUrl && (
          <div className="text-center mt-4">
            <Button
              onClick={() =>
                (window.location = redirectUrl as unknown as Location)
              }
            >
              Continue
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
