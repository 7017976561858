import { Stats } from "../app/statsSlice";
import { GameResult } from "../types/GameResult";
import GameState from "../types/GameState";
import { LetterPress } from "../types/LetterPress";
import UserStats from "../types/UserStats";
import * as api from "./api";
import { formatLocalTime } from "./dates";

export const roundToThreeDp = (num: number): number => {
  return Math.round((num + Number.EPSILON) * 1000) / 1000;
};

const defaultUserStats: UserStats = {
  averageCorrectLettersPercentage: 0,
  averageLivesUsed: 0,
  averagePaidVowelsUsed: 0,
  currentPlayStreak: 0,
  currentWinStreak: 0,
  latestGameNumber: 0,
  maxPlayStreak: 0,
  maxWinStreak: 0,
  statsVersion: 1,
  totalGames: 0,
  winPercentage: 0,
};

const getUserStats = (
  oldStats: UserStats = defaultUserStats,
  gameNumber: number,
  gameResult: GameState,
  correctLetterCount: number,
  totalLetterCount: number,
  livesUsed: number,
  paidVowelsUsed: number
): UserStats => {
  const totalGames = oldStats.totalGames + 1;
  const winPercentage = roundToThreeDp(
    (oldStats.winPercentage * oldStats.totalGames +
      (gameResult === GameState.SOLVED ? 100 : 0)) /
    totalGames
  );
  const averageCorrectLettersPercentage = roundToThreeDp(
    (oldStats.averageCorrectLettersPercentage * oldStats.totalGames +
      (correctLetterCount / totalLetterCount) * 100) /
    totalGames
  );
  const averageLivesUsed = roundToThreeDp(
    (oldStats.averageLivesUsed * oldStats.totalGames + livesUsed) / totalGames
  );
  const averagePaidVowelsUsed = roundToThreeDp(
    (oldStats.averagePaidVowelsUsed * oldStats.totalGames + paidVowelsUsed) /
    totalGames
  );

  const currentWinStreak =
    gameResult === GameState.SOLVED
      ? (gameNumber == oldStats.latestGameNumber + 1
        ? oldStats.currentWinStreak
        : 0) + 1
      : 0;

  const maxWinStreak = Math.max(oldStats.maxWinStreak, currentWinStreak);

  const currentPlayStreak =
    gameNumber == oldStats.latestGameNumber + 1
      ? oldStats.currentPlayStreak + 1
      : 1;

  const maxPlayStreak = Math.max(oldStats.maxPlayStreak, currentPlayStreak);

  const latestGameNumber = gameNumber;

  return {
    averageCorrectLettersPercentage,
    averageLivesUsed,
    averagePaidVowelsUsed,
    currentPlayStreak: currentPlayStreak,
    currentWinStreak: currentWinStreak,
    latestGameNumber,
    maxPlayStreak: maxPlayStreak,
    maxWinStreak,
    statsVersion: 1,
    totalGames,
    winPercentage,
  };
};

const getPersistenceKey = () => localStorage.getItem("persistenceKey");

const getLetterPresses = (gameNumber: number) => {
  let existingString: string | null = null;
  if (getPersistenceKey() === gameNumber.toString()) {
    // get existing from localStorage
    existingString = localStorage.getItem("letterPress");
  }

  const existing = existingString
    ? (JSON.parse(existingString) as LetterPress[])
    : [];

  return existing;
};

const postStats = async (
  gameNumber: number,
  gameHash: string,
  letterPresses: LetterPress[],
  stats: UserStats,
  result: GameResult,
  duration: number | null,
  isArchive: boolean = false
): Promise<Stats> => {
  console.log({ gameNumber, letterPresses });

  const date = new Date();
  const localTime = formatLocalTime(date);
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const statsResponse = await api.finishGame(
    gameNumber,
    gameHash,
    letterPresses,
    stats,
    result,
    localTime,
    localTimeZone,
    duration,
    isArchive
  );

  return statsResponse.data;
};
export default getUserStats;

export { defaultUserStats, getLetterPresses, postStats };
