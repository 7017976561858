import { startAppListening } from "./listenerMiddleware";
import { track } from "./trackingSlice";
import { trackEvent } from "../helpers/tracking";

export const registerTrackingListeners = async () => {
  startAppListening({
    actionCreator: track,
    effect: async (action) => {
      const { eventName, properties } = action.payload;
      trackEvent(eventName, properties || {});
    },
  });
};
