import { useState, useEffect } from "react";
import { createPortal } from "react-dom";

type Props = {
  message: string;
  onToastRemoved?: () => void;
};

const getOpacity = (showToast: boolean | undefined) => {
  if (showToast === undefined) {
    return "opacity-0";
  }

  if (showToast) {
    return "opacity-100";
  }

  return "opacity-0";
};

const Toast = ({ message, onToastRemoved }: Props) => {
  const [showToast, setShowToast] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setShowToast(true);
    setTimeout(() => setShowToast(undefined), 2000);
  }, []);

  function handleTransitionEnd(e: React.TransitionEvent<HTMLDivElement>) {
    if (e.propertyName === "opacity") {
      const { opacity } = getComputedStyle(e.target as Element);
      if (parseFloat(opacity) === 0) {
        setShowToast(false);
        if (onToastRemoved) {
          onToastRemoved();
        }
      }
    }
  }

  if (showToast !== false) {
    return (
      <>
        {createPortal(
          <div className="absolute top-12 z-50 w-full items-center">
            <div className="flex justify-center">
              <div
                className={`transition-all duration-300 ${getOpacity(
                  showToast
                )} flex justify-center bg-slate-700 text-white font-bold text-sm rounded-lg max-w-sm`}
                onTransitionEnd={handleTransitionEnd}
              >
                <p className="inline-block max-w-sm p-4 text-center">
                  {message}
                </p>
              </div>
            </div>
          </div>,
          document.getElementById("toast-container") ?? document.body
        )}
      </>
    );
  }

  return null;
};

export default Toast;
