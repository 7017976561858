import {
  GuideMessage,
  clearShowGuideTimeouts,
  closeGuide,
  selectGameIndex,
  selectGuideMessage,
  setGameIndex,
  setShowGuide,
  setShowMessage,
  showGuide,
} from "./learningSlice";
import { startAppListening } from "./listenerMiddleware";
import { track } from "./trackingSlice";

const registerLearningListeners = async () => {
  let showMessageTimeout: NodeJS.Timeout | undefined;
  let showGuideTimeout: NodeJS.Timeout | undefined;

  startAppListening({
    actionCreator: closeGuide,
    effect: async (_, api) => {
      api.dispatch(setShowGuide(false));
      setTimeout(() => {
        api.dispatch(setShowMessage(false));
      }, 150);
    },
  });

  startAppListening({
    actionCreator: showGuide,
    effect: async (_, api) => {
      // don't show if first message and not first game
      const gameIndex = selectGameIndex(api.getState());
      const guideMessage = selectGuideMessage(api.getState());
      if (gameIndex !== 0 && guideMessage === GuideMessage.firstGameIntro) {
        return;
      }

      showMessageTimeout = setTimeout(() => {
        api.dispatch(setShowMessage(true));
      }, 100);

      showGuideTimeout = setTimeout(() => {
        api.dispatch(setShowGuide(true));
      }, 150);
    },
  });

  startAppListening({
    actionCreator: clearShowGuideTimeouts,
    effect: async () => {
      if (showMessageTimeout) {
        clearTimeout(showMessageTimeout);
      }

      if (showGuideTimeout) {
        clearTimeout(showGuideTimeout);
      }
    },
  });

  startAppListening({
    actionCreator: setGameIndex,
    effect: async (action, api) => {
      api.dispatch(
        track({
          eventName: "start_tutorial",
          properties: { gameIndex: action.payload },
        })
      );
    },
  });
};

export default registerLearningListeners;
