import { useEffect, useMemo } from "react";
import Gameplay from "./Gameplay";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  clearLetterPressesForLearningGames,
  loadPuzzle,
  setHasLoadingError,
  setLearningEndText,
  startLoading,
} from "../app/gameSlice";
import { logError } from "../helpers/tracing";
import { useParams } from "react-router-dom";
import Button from "./Button";
import { getGuideMessageText } from "../helpers/guide";
import { clearToasts } from "../app/toastSlice";
import {
  GuideMessage,
  clearShowGuideTimeouts,
  closeGuide,
  selectGuideMessage,
  selectShowGuide,
  selectShowMessage,
  setGameIndex,
  showGuide as showGuideAction,
} from "../app/learningSlice";
import { useMessage } from "../hooks/useMessage";
import { getPlatform } from "../helpers/system";

const puzzles = [
  {
    finishText:
      "You know your numbers! Let's move on to the next theme, or play today's puzzle if you think you're ready.",
    gameId: 100001,
    theme: "Numbers",
    words: ["one", "two", "three", "four", "five"],
  },
  {
    finishText:
      "What a colourful game! Try the next training game, or are you game for today's puzzle?",
    gameId: 100002,
    theme: "Colours",
    words: ["red", "blue", "green", "yellow", "purple"],
  },
  {
    finishText: "You've got the hang of it! Now onto the main event.",
    gameId: 100003,
    theme: "Animals",
    words: ["dog", "cat", "bird", "fish", "rabbit"],
  },
];

export const TrainingGame = () => {
  const dispatch = useAppDispatch();
  const showGuide = useAppSelector(selectShowGuide);
  const showMessage = useAppSelector(selectShowMessage);
  const guideMessage = useAppSelector(selectGuideMessage);

  const { id } = useParams();

  useEffect(() => {
    return () => {
      dispatch(clearLetterPressesForLearningGames());
      dispatch(clearToasts());
    };
  }, [dispatch]);

  useEffect(() => {
    try {
      dispatch(startLoading());

      const { gameId, theme, words, finishText } = puzzles[Number(id)];

      dispatch(clearLetterPressesForLearningGames());
      // TODO: Use gameId exclusively at some point
      dispatch(loadPuzzle({ gameIndex: gameId, learning: true, theme, words }));
      dispatch(setGameIndex(Number(id)));
      dispatch(setLearningEndText(finishText ?? ""));
    } catch (error) {
      logError(error, { message: "Error loading game" });
      dispatch(setHasLoadingError(true));
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(showGuideAction());

    return () => {
      dispatch(clearShowGuideTimeouts());
    };
  }, [guideMessage, dispatch]);

  const handleCloseGuide = () => {
    dispatch(closeGuide());
  };

  const guideMessageText = useMemo(
    () => getGuideMessageText(guideMessage),
    [guideMessage]
  );

  const messageIntroContinue = useMessage("guide_intro_continue_button").body;
  const messageContinue = useMessage("guide_continue_button").body;

  const guideButtonText = useMemo(() => {
    switch (guideMessage) {
      case GuideMessage.firstGameIntro:
        return messageIntroContinue;
      default:
        return messageContinue;
    }
  }, [guideMessage, messageIntroContinue, messageContinue]);

  const isNativeIOS = ["ios", "ipad"].includes(getPlatform());

  return (
    <>
      <Gameplay />
      {showMessage && (
        <div
          className={`absolute bottom-0 left-0 right-0 z-50 h-56 flex items-center justify-center backdrop-blur-md transition-opacity ${
            showGuide ? "opacity-100" : "opacity-0"
          } ${isNativeIOS ? "h-[15.4rem]" : ""}`}
        >
          <div className="w-10/12 rounded-md p-2 text-center">
            <p className="text-sm font-bold">{guideMessageText}</p>
            <Button
              className="text-sm mt-2 px-2 py-1.5"
              onClick={() => handleCloseGuide()}
            >
              {guideButtonText}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
