const colours = {
  consonant: {
    background: {
      dark: "dark:bg-gray-400",
      light: "bg-gray-400",
    },
    border: {
      dark: "dark:border-gray-400",
      light: "border-gray-400",
    },
  },
  correct: {
    background: {
      dark: "dark:bg-green-700",
      light: "bg-green-500",
    },
    border: {
      dark: "dark:border-green-700",
      light: "border-green-500",
    },
  },
  disabled: {
    background: {
      dark: "dark:bg-slate-800",
      light: "bg-slate-100",
    },
    border: {
      dark: "dark:border-slate-800",
      light: "border-slate-100",
    },
  },
  incorrect: {
    background: {
      dark: "dark:bg-red-700",
      light: "bg-red-500",
    },
    border: {
      dark: "dark:border-red-700",
      light: "border-red-500",
    },
  },
  key: {
    active: {
      background: {
        dark: "dark:active:bg-slate-900",
        light: "active:bg-slate-300",
      },
      border: {
        dark: "dark:active:border-slate-900",
        light: "active:border-slate-300",
      },
    },
    background: {
      dark: "dark:bg-gray-800",
      light: "bg-gray-200",
    },
    border: {
      dark: "dark:border-gray-800",
      light: "border-gray-200",
    },
  },
  vowel: {
    background: {
      dark: "dark:bg-blue-300",
      light: "bg-blue-300",
    },
    border: {
      dark: "dark:border-blue-300",
      light: "border-blue-300",
    },
    fill: {
      dark: "dark:fill-blue-300",
      light: "fill-blue-300",
    },
  },
};

export { colours };
