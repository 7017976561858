import { selectStats } from "../../app/gameSlice";
import { useAppSelector } from "../../hooks";
import { useMessage } from "../../hooks/useMessage";
import { StatPanel } from "./StatPanel";

export const StatsSummary = () => {
  const stats = useAppSelector(selectStats);

  const played = useMessage("played_stat");
  const playStreak = useMessage("play_streak_stat");
  const wonStat = useMessage("won_stat");
  const longestPlayStreak = useMessage("longest_play_streak_stat");

  return (
    <div className="border border-slate-200 rounded-lg py-3 px-2 mt-3 mx-3">
      <h2 className="text-center font-bold mb-2">Statistics</h2>
      <div className="grid grid-cols-4 gap-4">
        <StatPanel label={played.body} value={stats?.totalGames.toString()} />
        <StatPanel
          label={playStreak.body}
          value={stats?.currentPlayStreak.toString()}
        />
        <StatPanel
          label={wonStat.body}
          value={`${stats?.winPercentage.toFixed(0)}%`}
        />
        <StatPanel
          label={longestPlayStreak.body}
          value={stats?.maxPlayStreak.toString()}
        />
      </div>
    </div>
  );
};
