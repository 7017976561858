import { Message, selectMessage } from "../app/messagesSlice";
import { useAppSelector } from "../hooks";
import format from "string-template";

const messageCache: Record<string, Message> = {};

export const useMessage = (
  tag: string,
  args: Record<string, unknown> | undefined = undefined
) => {
  const newMessage = useAppSelector((state) => selectMessage(state, tag));

  const message = messageCache[tag] ?? newMessage;
  messageCache[tag] = message;

  // setMessageTrackingVariant(tag, message.variant!);

  if (args) {
    return { body: format(message.body, args), tag };
  }

  return { body: message.body, tag };
};
