const getMidnightTonight = () => {
  const midnight = new Date();
  midnight.setHours(23, 59, 59, 999);
  return midnight;
};

const getSecondsUntilDate = (targetDate: Date) => {
  const now = new Date();
  const secondsUntilDate = (targetDate.getTime() - now.getTime()) / 1000;
  return secondsUntilDate;
};

const formatLocalTime = (date: Date) =>
  `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
    "0" + date.getDate()
  ).slice(-2)}T${("0" + date.getHours()).slice(-2)}:${(
    "0" + date.getMinutes()
  ).slice(-2)}:${("0" + date.getSeconds()).slice(
    -2
  )}.${date.getMilliseconds()}`;

export const formatFullDate = (date: Date) =>
  new Intl.DateTimeFormat(undefined, {
    dateStyle: "full",
    timeZone: "UTC",
  }).format(date);

export const formatLocalDate = (date: Date) => {
  // get date not as UTC string in ISO format
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""
    }${day}`;
};

export { formatLocalTime, getMidnightTonight, getSecondsUntilDate };
