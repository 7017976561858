import posthog from "posthog-js";

enum Feature {
  VerboseLogging = "verbose-logging",
}

const getAllFeatures: () => string[] = () => Object.values(Feature);

export const isFeatureEnabled = async (feature: Feature) => {
  return posthog.isFeatureEnabled(feature);
};

export { Feature, getAllFeatures };
