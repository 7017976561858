import GameState from "../types/GameState";
import { useAppSelector } from "../hooks";
import { useMessage } from "../hooks/useMessage";
import { selectGameStatus } from "../app/gameSlice";
import { logInfo } from "../helpers/tracing";

const Theme = () => {
  logInfo({ message: "Rendering Theme" });

  const { puzzle, isLearning } = useAppSelector((state) => state.game);

  const gameState = useAppSelector(selectGameStatus);

  const { theme } = puzzle;

  const todaysTheme = useMessage(
    isLearning ? "learning_theme" : "todays_theme"
  );

  return (
    <div className="text-center text-sm top-0 w-full px-2">
      {gameState !== GameState.IN_PROGRESS && (
        <>
          <p className="mt-2">
            <span className="text-sm">{todaysTheme.body}</span>
            <br />
            <span className="font-bold text-lg">{theme}</span>
          </p>
        </>
      )}
    </div>
  );
};
export default Theme;
