import Button from "./Button";
import { useState, useEffect } from "react";
import ToolsIcon from "./icons/ToolsIcon";
import {
  getBuildHash,
  getNativeAppVersion,
  getVersionNumber,
} from "../helpers/system";
import { clearTitle, setTitle } from "../app/pageSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectFeatures } from "../app/featuresSlice";

const Diagnostics = () => {
  const [uid, setUid] = useState<string | null>(null);

  useEffect(() => {
    const uid = localStorage.getItem("uid");
    setUid(uid);
  }, []);

  const version = `${getBuildHash()}-${import.meta.env.VITE_BUILD_NUMBER ?? 0}`;
  const nativeVersion = getNativeAppVersion();

  const copyUidToClipboard = () => {
    const nativeVersionString = nativeVersion
      ? ` ${nativeVersion[0]} ${nativeVersion[1]}`
      : "";
    navigator.clipboard.writeText(
      `UID: ${uid!}\nVersion: ${version}${nativeVersionString}`
    );
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTitle({ title: "Diagnostics" }));
    document.title = "Hang Five - Diagnostics";
    return () => {
      dispatch(clearTitle());
    };
  }, [dispatch]);

  const features = useAppSelector(selectFeatures);

  return (
    <div className="flex w-full h-full items-center mt-5">
      <div className="text-center w-full px-5 dark:text-slate-400">
        <ToolsIcon
          size={90}
          className="fill-gray-400 mx-auto dark:fill-gray-600"
        />
        <h1 className="text-xl font-bold mb-2 mt-5">Diagnostics</h1>
        <p>
          Your UID is <code>{uid}</code>
        </p>
        <p>
          Version: {getVersionNumber()} <code>{version}</code>
          {nativeVersion && (
            <span>
              {" "}
              {nativeVersion[0]} {nativeVersion[1]}
            </span>
          )}
        </p>

        <p>
          Features: <span>{features.join(", ")}</span>
        </p>
        <Button className="my-5 mx-2" onClick={copyUidToClipboard}>
          Copy to clipboard
        </Button>
        {/* <p>
          <Link to="/beta" className="underline">
            Join the beta
          </Link>
        </p> */}
      </div>
    </div>
  );
};

export default Diagnostics;
