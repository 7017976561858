import Modal from "./Modal";
import ExternalLinkIcon from "./icons/ExternalLinkIcon";
import { setHasSeenInfo } from "../app/settingsSlice";
import { useAppDispatch } from "../hooks";
import { track } from "../app/trackingSlice";
import { useEffect } from "react";
import { hideModal } from "../app/modalSlice";

const InfoModal = () => {
  const dispatch = useAppDispatch();

  const onCloseClick = () => {
    dispatch(setHasSeenInfo());
    dispatch(track({ eventName: "info_closed" }));
    dispatch(hideModal());
  };

  const handleExternalLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
    dispatch(
      track({
        eventName: "click_external_link",
        properties: {
          targetUrl: (e.target as HTMLAnchorElement).href,
        },
      })
    );
  };

  useEffect(() => {
    if (window.__uspapi) {
      window.__uspapi("addLink", 1);
    }
  }, []);

  return (
    <Modal
      onCloseClick={onCloseClick}
      buttonText="Let's play"
      footer={
        <>
          <div className="text-xs mt-4 flex items-justify">
            <div className="flex-1">
              <a
                href="https://zemobo.com/"
                className="flex items-center hover:underline"
                target="_blank"
                onClick={handleExternalLink}
              >
                Made by Zemobo
                <ExternalLinkIcon
                  className="ml-2 fill-gray-500 dark:fill-slate-400"
                  size={11}
                />
              </a>
            </div>
            <div className="flex-1 ">
              <a
                href="https://zemobo.com/privacy"
                className="flex items-center justify-end hover:underline"
                target="_blank"
                onClick={handleExternalLink}
              >
                Privacy policy
                <ExternalLinkIcon
                  className="ml-2 fill-gray-500 dark:fill-slate-400"
                  size={11}
                />
              </a>
            </div>
          </div>
          <div className="text-right text-xs">
            <span data-ccpa-link="1"></span>
          </div>
        </>
      }
    >
      <p className="mb-2">
        <strong className="text-bold">Hang Five</strong> is a daily
        word-guessing game. There are five words and they all share a common
        theme.
      </p>
      <p className="mb-2">You have five lives.</p>
      <p className="mb-2"> If you pick a wrong letter, you lose a life.</p>
      <p className="mb-2">
        Vowels are very useful, and go in the blue squares. Choosing a vowel
        early in the game will cost you a life.
      </p>
      <p className="mb-2">
        Once there are only vowels left, they become free to use.
      </p>
      <p>Once you've guessed all the words, the day's theme is revealed.</p>
    </Modal>
  );
};

export default InfoModal;
