import { startAppListening } from "./listenerMiddleware";
import { share } from "./sharingSlice";
import {
  selectGameStatus,
  selectLivesLeft,
  selectLostLifeReasons,
  selectPuzzle,
  selectScore,
  trackShare,
} from "./gameSlice";
import { ShareResult, share as shareExternally } from "../helpers/sharing";
import LifeStatus from "../types/LifeStatus";
import {
  BOUGHT_VOWEL_ICON,
  LOST_ICON,
  REMAINING_LIFE_ICON,
  WON_ICON,
  WRONG_LETTER_ICON,
} from "../constants/icons";
import GameStatus from "../types/GameState";
import { showToast } from "./toastSlice";
import { track } from "./trackingSlice";
import { logInfo } from "../helpers/tracing";

const registerSharingListeners = async () => {
  startAppListening({
    actionCreator: share,
    effect: async (_action, api) => {
      logInfo({ message: "Sharing game" });
      const lostLifeReasons = selectLostLifeReasons(api.getState());
      const status = selectGameStatus(api.getState());
      const puzzle = selectPuzzle(api.getState());

      const livesLeft = selectLivesLeft(api.getState());

      const hearts = [];
      for (const reason of lostLifeReasons) {
        if (reason === LifeStatus.INCORRECT) {
          hearts.push(WRONG_LETTER_ICON);
        } else if (reason == LifeStatus.VOWEL) {
          hearts.push(BOUGHT_VOWEL_ICON);
        }
      }

      for (let index = 0; index < livesLeft; index++) {
        hearts.push(REMAINING_LIFE_ICON);
      }

      const resultIndicator =
        status === GameStatus.SOLVED ? WON_ICON : LOST_ICON;

      const livesLeftString = hearts.reverse().join("");

      const score = selectScore(api.getState());

      const textToShare = `Hang Five ${puzzle.gameIndex}\n${livesLeftString} ${resultIndicator}\n${score}%\nplayhangfive.com`;

      const result = await shareExternally(textToShare);

      switch (result) {
        case ShareResult.CopiedToClipboard:
          api.dispatch(showToast({ message: "Copied to clipboard" }));

          api.dispatch(
            track({
              eventName: "share",
              properties: {
                gameIndex: puzzle.gameIndex,
                shareMethod: "Clipboard",
              },
            })
          );

          break;

        case ShareResult.Shared:
          api.dispatch(
            track({
              eventName: "share",
              properties: {
                gameIndex: puzzle.gameIndex,
                shareMethod: "Web share",
              },
            })
          );

          break;

        case ShareResult.Failed:
          api.dispatch(showToast({ message: "Failed to share" }));
          break;
      }

      api.dispatch(
        trackShare({
          gameNumber: puzzle.gameIndex,
          shareMethod: result,
        })
      );
    },
  });
};

export default registerSharingListeners;
