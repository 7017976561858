import { registerSW } from "virtual:pwa-register";

const registerServiceWorker = () => {
  registerSW({
    immediate: true,
    onNeedRefresh() {
      console.log("New version of service worker available.");
    },
  });
};

export { registerServiceWorker };
