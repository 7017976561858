import { createAction } from "@reduxjs/toolkit";

export type TrackingEvent =
  | "start_game"
  | "continue_game"
  | "return_to_finished_game"
  | "start_tutorial"
  | "finish_game"
  | "finish_first_game"
  | "finish_tutorial"
  | "press_letter"
  | "stats_clicked"
  | "stats_closed"
  | "info_clicked"
  | "info_closed"
  | "click_external_link"
  | "share";

export type TrackingProperty =
  | "gameIndex"
  | "gameResult"
  | "targetUrl"
  | "puzzleDate"
  | "shareMethod"
  | "gameCount";

export interface TrackPayload {
  eventName: TrackingEvent;
  properties?: Partial<Record<TrackingProperty, unknown>>;
}

export const track = createAction<TrackPayload>("track");
