import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

export const postStats = createAction("postStats");
export const calculateStats = createAction("calculateStats");
export const fetchStats = createAction<number>("fetchStats");

export type Stats = {
  solvedRate?: number;
  userPercentile?: number;
  gameIndex?: number;
  userHasPlayed: boolean;
};

const initialState: Stats = { userHasPlayed: false };

export const gameSlice = createSlice({
  initialState,
  name: "stats",
  reducers: {
    setStats: (state, action: PayloadAction<Stats>) => {
      state.solvedRate = action.payload.solvedRate;
      state.userPercentile = action.payload.userPercentile;
      state.gameIndex = action.payload.gameIndex;
      state.userHasPlayed = action.payload.userHasPlayed;
    },
  },
});

export const selectStats = (state: RootState) => state.stats;

export const { setStats } = gameSlice.actions;
export default gameSlice.reducer;
