import GameScreen from "./GameScreen";
import Toast from "./Toast";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  makeGuess,
  selectGuessedLetters,
  selectHasLoadingError,
  selectIsLoading,
  selectPuzzle,
  startPlaying,
} from "../app/gameSlice";
import { selectToastMessages } from "../app/toastSlice";
import { useEffect } from "react";
import ErrorScreen from "./ErrorScreen";
import { logInfo } from "../helpers/tracing";

const Gameplay = () => {
  const showError = useAppSelector(selectHasLoadingError);
  const isLoading = useAppSelector(selectIsLoading);

  const toastMessages = useAppSelector(selectToastMessages);

  const dispatch = useAppDispatch();

  const onChange = (input: string) => {
    console.log("guessed", input);
    dispatch(makeGuess({ letter: input }));
  };

  // calculate max number of letters not guessed in each word
  const puzzle = useAppSelector(selectPuzzle);
  const allGuessedLetters = useAppSelector(selectGuessedLetters);

  useEffect(() => {
    dispatch(startPlaying());
  }, [dispatch, puzzle]);

  const lettersNotGuessed = puzzle.words.map((word) =>
    word
      .split("")
      .filter((letter) => !allGuessedLetters.includes(letter.toLowerCase()))
  );

  const maxLettersNotGuessed = Math.max(
    ...lettersNotGuessed.map((letters) => letters.length)
  );

  console.log({ lettersNotGuessed, maxLettersNotGuessed });

  logInfo({ message: "Rendering GamePlay" });

  return (
    <>
      <GameScreen
        onKeyPress={onChange}
        loadNextGame={() => window.location.reload()}
        isLoading={isLoading ?? false}
      />

      {showError && <ErrorScreen />}

      {toastMessages.map((message, index) => (
        <Toast key={index} message={message} />
      ))}
    </>
  );
};

export default Gameplay;
