import { createSlice, createSelector } from '@reduxjs/toolkit';
import { isNative } from '../helpers/system';

// Define the initial state
interface SystemState {
    isNative: boolean;
}

const initialState: SystemState = {
    isNative: isNative(),
};

// Define the slice
const systemSlice = createSlice({
    initialState,
    name: 'system',
    reducers: {
        // Add reducer functions here if needed
    },
});

// Define a selector to get the isNative value
export const selectIsNative = createSelector(
    (state: { system: SystemState }) => state.system.isNative,
    (isNative) => isNative
);

// Export the slice and the selector
// export const { actions: systemActions, reducer: systemReducer } = systemSlice;
export default systemSlice.reducer;