import { GameState } from "../app/gameSlice";
import UserStats from "../types/UserStats";
import { defaultUserStats } from "./stats";
import { SettingsState } from "../app/settingsSlice";
import { LetterPress } from "../types/LetterPress";

type LegacyPersistedGameState = {
  letters?: string[];
  correctKeys?: string[];
  incorrectKeys?: string[];
  gameState?: number;
  lostLifeReasons?: number[];
  userStats: UserStats;
};

export const readLegacySavedGameState = (
  persistenceKey: string
): LegacyPersistedGameState => {
  const savedPersistenceKey = localStorage.getItem("persistenceKey");
  const GameStatusRaw = localStorage.getItem("gameState");

  let gameState: LegacyPersistedGameState;

  if (GameStatusRaw) {
    gameState = JSON.parse(GameStatusRaw);

    if (savedPersistenceKey === persistenceKey) {
      return gameState;
    }

    return {
      userStats: gameState.userStats ?? defaultUserStats,
    };
  } else {
    return {
      userStats: defaultUserStats,
    };
  }
};

export const saveGameToLocalStorage = (game: GameState) => {
  const {
    letterPresses,
  } = game;
  const { gameIndex, gameHash, puzzleId } = game.puzzle;
  const { startTimestamp } = game;

  localStorage.setItem(
    "game",
    JSON.stringify({
      gameHash,
      gameIndex,
      puzzleId,
      startTimestamp,
    })
  );

  saveLetterPressToLocalStorage(letterPresses);
};

export const saveStatsToLocalStorage = (stats: UserStats) => {
  localStorage.setItem("stats", JSON.stringify(stats));
};

type PersistedGame = {
  puzzleId: string;
  gameHash: string;
  gameIndex: number;
  startTimestamp?: number;
};

const readStatsFromLocalStorage = (): UserStats => {
  const statsString = localStorage.getItem("stats");
  if (!statsString) {
    return defaultUserStats;
  }

  return JSON.parse(statsString) as UserStats;
};

export const readGameFromLocalStorage = (
  gameIndex: number | undefined = undefined
): GameState => {
  console.log(`In future will load game index ${gameIndex}`);

  const gameString = localStorage.getItem("game");
  const stats = readStatsFromLocalStorage();


  if (!gameString) {
    return {
      isArchive: false,
      isLearning: false,
      isLoading: true,
      letterPresses: readLetterPressFromLocalStorage(),
      puzzle: {
        date: "",
        gameHash: "",
        gameIndex: 0,
        puzzleId: "",
        theme: "",
        words: [],
      },
      startTimestamp: undefined,
      stats,
    };
  }

  const game = JSON.parse(gameString) as PersistedGame;

  return {
    isArchive: false,
    isLearning: false,
    isLoading: true,
    letterPresses: readLetterPressFromLocalStorage(),
    puzzle: {
      date: "",
      gameHash: game.gameHash,
      gameIndex: game.gameIndex,
      puzzleId: game.puzzleId,
      theme: "",
      words: [],
    },
    startTimestamp: game.startTimestamp,
    stats,
  };
};

export const saveSettingsToLocalStorage = (settings: SettingsState) => {
  localStorage.setItem("settings", JSON.stringify(settings));
};

export const readSettingsFromLocalStorage = (): SettingsState => {
  const settingsString = localStorage.getItem("settings");
  if (!settingsString) {
    const legacyHasSeenInfo = localStorage.getItem("hasSeenInfo");

    if (legacyHasSeenInfo) {
      localStorage.removeItem("hasSeenInfo");
    }

    return {
      hasMigrated: false,
      hasSeenInfo: legacyHasSeenInfo === "1",
    };
  }

  return JSON.parse(settingsString) as SettingsState;
};

const readLetterPressFromLocalStorage = (): LetterPress[] => {
  const letterPressString = localStorage.getItem("letterPress");
  if (!letterPressString) {
    return [];
  }

  return JSON.parse(letterPressString) as LetterPress[];
};

const saveLetterPressToLocalStorage = (letterPress: LetterPress[]) => {
  localStorage.setItem("letterPress", JSON.stringify(letterPress));
};

export const clearLetterPress = () => {
  localStorage.removeItem("letterPress");
};

export const clearGame = () => {
  localStorage.removeItem("game");
};

export type { LegacyPersistedGameState, PersistedGame };
