import GameState from "../types/GameState";
import { useAppSelector } from "../hooks";
import { useMessage } from "../hooks/useMessage";
import { selectGameStatus } from "../app/gameSlice";
import { logInfo } from "../helpers/tracing";

type Props = {
  className?: string;
};

const GameResult = ({ className = "" }: Props) => {
  logInfo({ message: "Rendering GameResult" });

  const status = useAppSelector(selectGameStatus);

  const actualClassName = `${className} text-sm font-bold mb-2`;

  const lostMessage = useMessage("lost");
  const wonMessage = useMessage("won");

  return (
    <>
      {status === GameState.LOST && (
        <p className={actualClassName}>{lostMessage.body}</p>
      )}
      {status === GameState.SOLVED && (
        <p className={actualClassName}>{wonMessage.body}</p>
      )}
    </>
  );
};
export default GameResult;
