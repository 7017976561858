import axios, { AxiosRequestConfig } from "axios";
import axiosRetry from "axios-retry";
import { decodeJwt } from "jose";
import { getPlatform, getUid, getVersionNumber } from "./system";
import { logError } from "./tracing";

const apiBaseUrl = import.meta.env.VITE_API_BASE_URL;

enum RequestType {
  Api = "api",
  Content = "content",
}

const axiosInstance = axios.create({
  // Your base URL and other Axios configuration options
  baseURL: apiBaseUrl,
  headers: { "X-Hang-Five-Version": `${getPlatform()}/${getVersionNumber()}` },
});

axiosRetry(axiosInstance, {
  retries: 3,
  retryDelay: () => 500,
});

let currentToken: string | null = null; // Store the current token

// Function to retrieve the bearer token (you need to implement this)
const acquireToken = async (): Promise<string | null> => {
  const uid = getUid();
  const tokenUrl = "/token";
  const params = { uid: uid };

  try {
    const response = await axiosInstance.get(tokenUrl, { params });

    if (response.status !== 200) {
      return null;
    }

    const { token } = response.data;

    currentToken = token;

    return token;
  } catch (err) {
    logError(err, { message: "Error acquiring token" });
    return null;
  }
};

const getTokenExpirationDate = (token: string): Date | null => {
  const claims = decodeJwt(token);
  if (claims && claims.exp) {
    const date = new Date(0);
    date.setUTCSeconds(claims.exp);

    console.log("expiry date", date);

    return date;
  }
  return null;
};

const isTokenValid = (token: string | null | undefined): boolean => {
  // Implement your JWT validation logic here, e.g., checking expiration
  if (!token) {
    return false; // Token is invalid
  }
  try {
    // has currentTokenExpiry passed?
    const expiry = getTokenExpirationDate(token);
    const hasExpired = expiry && Date.now() >= expiry.getTime();

    if (hasExpired) {
      return false; // Token is invalid or expired
    }
    return true; // Token is valid
  } catch (error) {
    console.log("Error validating token", error);
    return false; // Token is invalid
  }
};

const request = async (
  method: string,
  path: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any,
  anonymous: boolean = false,
  type: RequestType = RequestType.Api
) => {
  const config: AxiosRequestConfig = {
    data: body,
    method,
    url: path,
  };

  if (type === RequestType.Content) {
    config.baseURL = import.meta.env.VITE_CONTENT_BASE_URL;
  }

  try {
    if (!anonymous) {
      if (!currentToken || !isTokenValid(currentToken)) {
        // If there's no token or it's expired, get a new one.
        await acquireToken();
      }

      // Set the authorization header with the current token.
      if (currentToken) {
        console.log("Using token " + currentToken);

        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${currentToken}`,
        };
      } else {
        logError(new Error("No token"), { message: "No token" });
      }
    }

    const response = await axiosInstance(config);

    return response;
  } catch (error) {
    if (
      axios.isAxiosError(error) &&
      error.response &&
      error.response.status === 401
    ) {
      console.log("401 error");
      // The request was unauthorized; attempt to refresh the token.

      if ((await acquireToken()) && currentToken) {
        // Retry the original request with the new token.
        console.log("Using new token:", currentToken);

        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${currentToken}`,
        };
        return axiosInstance(config);
      } else {
        logError(new Error("No token"), {
          message: "No token after trying again",
        });
      }
    }
    // For other errors or when token refresh fails, pass the original error.
    logError(error, { body, message: `Error making request to ${path}`, path });

    throw error;
  }
};

export { request, isTokenValid, RequestType };
