import { selectGameStatus } from "../../app/gameSlice";
import { logInfo } from "../../helpers/tracing";
import { useAppSelector } from "../../hooks";
import { useMessage } from "../../hooks/useMessage";
import GameStatus from "../../types/GameState";

export const StatsTitle = ({
  className,
}: {
  className: string | undefined;
}) => {
  logInfo({ message: "Rendering StatsTitle" });

  const status = useAppSelector(selectGameStatus);

  const inProgressMessage = useMessage("stats_title.in_progress");
  const solvedMessage = useMessage("stats_title.solved");
  const lostMessage = useMessage("stats_title.lost");

  let message: string;

  switch (status) {
    case GameStatus.IN_PROGRESS:
      message = inProgressMessage.body;
      break;
    case GameStatus.SOLVED:
      message = solvedMessage.body;
      break;
    case GameStatus.LOST:
      message = lostMessage.body;
      break;
  }

  return <h2 className={className}>{message}</h2>;
};
