import { ReactNode, useEffect, useState } from "react";

type Props = {
  children: ReactNode;
  className?: string;
};

export const Reveal = ({ children, className = "" }: Props) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 10);
  }, []);

  return (
    <div
      className={`transition-opacity duration-500 ${
        visible ? "opacity-100" : "opacity-0"
      } ${className}`}
    >
      {children}
    </div>
  );
};
