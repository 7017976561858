import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { Feature } from "../helpers/features";

type FeaturesSlice = {
  features: Feature[];
};

const initialState: FeaturesSlice = {
  features: [],
};

const featuresSlice = createSlice({
  initialState,
  name: "features",
  reducers: {
    setFeatures: (state, action: PayloadAction<Feature[]>) => {
      state.features = action.payload;
    },
  },
});

export const { setFeatures } = featuresSlice.actions;

export const selectFeatures = createSelector(
  (state: RootState) => state.features,
  (features) => features.features
);

export default featuresSlice.reducer;
