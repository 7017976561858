import Button from "./Button";

const IOSMarketing = () => (
  <div className="p-8">
    <h1 className="text-xl text-center font-medium mb-8">
      Get Hang Five on the App Store!
    </h1>

    <p className="text-center">
      <Button
        onClick={() =>
          (window.location.href =
            "https://apps.apple.com/us/app/hang-five/id6736662743")
        }
      >
        Download it today
      </Button>
    </p>
  </div>
);

export default IOSMarketing;
