import { useNavigate } from "react-router-dom";
import Button from "./Button";
import { LEARN_PATH, PLAY_PATH } from "../constants/paths";
import { useMessage } from "../hooks/useMessage";

const HowToPlayScreen = () => {
  const navigate = useNavigate();

  const playLearningBody = useMessage("play_learning_game").body;

  return (
    <div className="m-5">
      <h1 className="text-center font-bold text-xl mb-4">
        {useMessage("how_to_play_title").body}
      </h1>
      <div className="text-sm">
        <p className="mb-2">
          <strong className="text-bold">Hang Five</strong> is a daily
          word-guessing game. There are five words and they all share a common
          theme.
        </p>
        <p className="mb-2">You have five lives.</p>
        <p className="mb-2">If you pick a wrong letter, you lose a life.</p>
        <p className="mb-2">
          Vowels are very useful, and go in the blue squares. Choosing a vowel
          early in the game will cost you a life.
        </p>
        <p className="mb-2">
          Once there are only vowels left, they become free to use.
        </p>
        <p>Once you've guessed all the words, the day's theme is revealed.</p>
      </div>
      <div className="my-3 text-center text-sm font-medium mx-7">
        <p className="">
          Want to get the hang of it before completing today's challenge?
        </p>
        <Button
          className="mt-2"
          onClick={() => navigate(LEARN_PATH + "0", { replace: true })}
        >
          {playLearningBody}
        </Button>
        <p className="mt-4">Or jump right into today's puzzle</p>

        <Button
          className="mt-2"
          onClick={() => navigate(PLAY_PATH, { replace: true })}
          variant="secondary"
        >
          {useMessage("stats_screen_play_todays_puzzle").body}
        </Button>
      </div>
    </div>
  );
};

export default HowToPlayScreen;
