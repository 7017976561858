import { selectTimeTaken } from "../../app/gameSlice";
import { useAppSelector } from "../../hooks";
import { useMessage } from "../../hooks/useMessage";
import { TimeStyle } from "../../types/TimeStyle";
import Time from "../Time";

export const StatsTimer = () => {
  const timeTaken = useAppSelector(selectTimeTaken);
  const finishedMessage = useMessage("stats.you_finished_in");

  if (!timeTaken) return null;

  return (
    <p className="mb-1">
      {finishedMessage.body}{" "}
      <span className="font-semibold">
        <Time seconds={timeTaken / 1000} style={TimeStyle.MINUTES_WORDS} />
      </span>
      .
    </p>
  );
};
