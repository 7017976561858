import { GuideMessage } from "../app/learningSlice";

export const getGuideMessageText = (guideMessage: GuideMessage | undefined) => {
  switch (guideMessage) {
    case GuideMessage.firstGameIntro:
      return (
        "Here are three puzzles to help you get started with Hang Five. " +
        "Once you've got the hang of it, move on to today's puzzle and " +
        "start your streak."
      );
    case GuideMessage.earlyVowel:
      return "You've used a vowel early. Using a vowel when there are still consonants left to guess will cost you a life.";
    case GuideMessage.lostGame:
      return "Oh no, you've used all your lives! Normally the game would end but you can keep going till you've solved it.";
    case GuideMessage.onlyVowelsLeft:
      return "You've only got vowels left to guess. They won't cost you a life any more.";
    default:
      return guideMessage;
  }
};
