const VOWELS = ["a", "e", "i", "o", "u"];

export const getLettersInWords = (words: string[]) => {
  return words
    .map((word) => word.split(""))
    .flat()
    .filter((v, i, a) => a.indexOf(v) === i);
};

export const isVowel = (letter: string | undefined): letter is string => {
  if (!letter) {
    return false;
  }

  return VOWELS.includes(letter);
};
