import { AppStore, RootState } from "./store";

const observeStore = <T>(
  store: AppStore,
  select: (state: RootState) => T,
  onChange: (newState: T, oldState: T) => void
) => {
  let currentState: T;

  function handleChange() {
    const nextState = select(store.getState());
    if (nextState !== currentState) {
      const prevState = currentState;

      currentState = nextState;

      onChange(nextState, prevState);
    }
  }

  const unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
};

export { observeStore };
