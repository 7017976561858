import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

export enum GuideMessage {
  firstGameIntro,
  earlyVowel,
  lostGame,
  onlyVowelsLeft,
}

interface LearningState {
  showGuide: boolean;
  showMessage: boolean;
  gameIndex?: number;
  guideMessage?: GuideMessage;
}

const initialState: LearningState = {
  showGuide: false,
  showMessage: false,
};

const learningSlice = createSlice({
  initialState,
  name: "learning",
  reducers: {
    setGameIndex(state, action: PayloadAction<number>) {
      state.gameIndex = action.payload;
    },
    setGuideMessage(state, action: PayloadAction<GuideMessage | undefined>) {
      state.guideMessage = action.payload;
    },
    setShowGuide(state, action: PayloadAction<boolean>) {
      state.showGuide = action.payload;
    },
    setShowMessage(state, action: PayloadAction<boolean>) {
      state.showMessage = action.payload;
    },
  },
});

export const closeGuide = createAction("learning/closeGuide");
export const showGuide = createAction("learning/showGuide");
export const clearShowGuideTimeouts = createAction(
  "learning/clearShowGuideTimeouts"
);

const selectLearningState = (state: { learning: LearningState }) =>
  state.learning;

export const selectShowGuide = (state: { learning: LearningState }) =>
  state.learning.showGuide;

export const selectShowMessage = (state: { learning: LearningState }) =>
  state.learning.showMessage;

export const selectGameIndex = (state: { learning: LearningState }) =>
  state.learning.gameIndex;

export const { setGameIndex, setGuideMessage, setShowGuide, setShowMessage } =
  learningSlice.actions;

export const selectGuideMessage = createSelector(
  selectLearningState,
  ({ guideMessage }) => guideMessage
);

export default learningSlice.reducer;
