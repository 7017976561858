import { share } from "../app/sharingSlice";
import { useAppDispatch } from "../hooks";
import { useMessage } from "../hooks/useMessage";
import Button from "./Button";

enum ShareMessageType {
  default,
  fromStats,
}

type Props = {
  className?: string;
  messageType?: ShareMessageType;
};

const ShareButton = ({ className, messageType }: Props) => {
  const dispatch = useAppDispatch();
  const onClick = async () => {
    dispatch(share());
  };

  const defaultShareMessage = useMessage("share_cta").body;
  const statsShareMessage = useMessage("share_stats_cta").body;

  return (
    <Button className={className ? className : ""} onClick={onClick}>
      {messageType === ShareMessageType.fromStats
        ? statsShareMessage
        : defaultShareMessage}
    </Button>
  );
};
export default ShareButton;
export { ShareMessageType };
