type Props = {
  className?: string;
  size?: number | string;
};

const HangFiveIcon = ({ className, size = 15 }: Props) => (
  <svg className={className} width={size} height={size} viewBox="0 0 600 600">
    <defs>
      <clipPath id="a">
        <path fill="none" strokeWidth="0" d="M62 108h598v397H62z" />
      </clipPath>
      <clipPath id="b">
        <path fill="none" strokeWidth="0" d="M62 108h598v397H62z" />
      </clipPath>
      <clipPath id="c">
        <path
          d="M138 114c-24 19-20 166-27 235-5 55-26 130-11 147 7 9 26 10 34 3 17-15 7-101 11-146l27-235s-14-6-25-6c-3 0-7 0-9 2"
          fill="none"
          strokeWidth="0"
        />
      </clipPath>
      <clipPath id="d">
        <path fill="none" strokeWidth="0" d="M94 108h84v397H94z" />
      </clipPath>
      <clipPath id="e">
        <path fill="none" strokeWidth="0" d="M94 108h79v397H94z" />
      </clipPath>
      <clipPath id="f">
        <path
          d="M469 136c-11 11-1 61-1 86l2 49c1 35 8 109 9 145 1 21-7 42 0 49 7 6 28 6 35 0 7-8 0-30-1-51-1-36-8-101-9-144-2-34-2-67 0-133 0 0-14-4-24-4-5 0-9 1-11 3"
          fill="none"
          strokeWidth="0"
        />
      </clipPath>
      <clipPath id="g">
        <path fill="none" strokeWidth="0" d="M457 131h69v341h-69z" />
      </clipPath>
      <clipPath id="h">
        <path fill="none" strokeWidth="0" d="M458 131h64v341h-64z" />
      </clipPath>
      <clipPath id="i">
        <path
          d="M353 131c-24 26-13 279 14 303 8 8 26 7 34-1 16-19-7-119-13-303 0 0-11-4-21-4-5 0-11 1-14 5"
          fill="none"
          strokeWidth="0"
        />
      </clipPath>
      <clipPath id="j">
        <path fill="none" strokeWidth="0" d="M342 123h71v317h-71z" />
      </clipPath>
      <clipPath id="k">
        <path fill="none" strokeWidth="0" d="M342 123h71v317h-71z" />
      </clipPath>
      <clipPath id="l">
        <path
          d="M250 151c-12 9-6 50-9 84-5 48-30 161-13 181 8 8 26 9 34 2 20-17 8-132 22-263 0 0-16-6-27-6l-7 2"
          fill="none"
          strokeWidth="0"
        />
      </clipPath>
      <clipPath id="m">
        <path fill="none" strokeWidth="0" d="M223 145h67v279h-67z" />
      </clipPath>
      <clipPath id="n">
        <path fill="none" strokeWidth="0" d="M223 145h61v279h-61z" />
      </clipPath>
      <clipPath id="o">
        <path
          d="m519 223-47 6c-27 3-71 10-97 16l-49 10-47 11c-24 7-55 15-211 72 0 0 2 28 12 32 20 9 87-34 124-46 31-10 61-19 85-25l45-11 48-10a2770 2770 0 0 1 141-21l44-1c36 1 78 1 87-10 7-8 6-27-2-34-4-3-12-4-22-4-32 0-86 13-111 15"
          fill="none"
          strokeWidth="0"
        />
      </clipPath>
      <clipPath id="p">
        <path fill="none" strokeWidth="0" d="M62 195h599v192H62z" />
      </clipPath>
    </defs>
    <path fill="#7c3aed" d="M-1-1h600v600H-1z" />
    <g clipPath="url(#a)">
      <g clipPath="url(#b)">
        <g clipPath="url(#c)">
          <g clipPath="url(#d)" style={{ isolation: "isolate" }}>
            <g clipPath="url(#e)">
              <path fill="#fff" d="m95 504 8-396 75 2-8 395z" />
            </g>
          </g>
        </g>
        <g clipPath="url(#f)">
          <g clipPath="url(#g)" style={{ isolation: "isolate" }}>
            <g clipPath="url(#h)">
              <path fill="#fff" d="m457 470 7-339 62 1-7 339z" />
            </g>
          </g>
        </g>
        <g clipPath="url(#i)">
          <g clipPath="url(#j)" style={{ isolation: "isolate" }}>
            <g clipPath="url(#k)">
              <path fill="#fff" d="m342 439 6-316 64 1-6 316z" />
            </g>
          </g>
        </g>
        <g clipPath="url(#l)">
          <g clipPath="url(#m)" style={{ isolation: "isolate" }}>
            <g clipPath="url(#n)">
              <path fill="#fff" d="m223 422 5-277 61 1-5 277z" />
            </g>
          </g>
        </g>
        <g clipPath="url(#o)">
          <g clipPath="url(#p)" style={{ isolation: "isolate" }}>
            <path fill="#fff" d="m62 375 4-180 595 13-4 180z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default HangFiveIcon;
