import { StatsTitle } from "./stats/StatsTitle";
import { StatsIntro } from "./stats/StatsIntro";
import { selectGameStatus, selectPuzzle, selectStats } from "../app/gameSlice";
import { StatsTimer } from "./stats/StatsTimer";
import GameStatus from "../types/GameState";
import ShareButton from "./ShareButton";
import StatsRank from "./stats/StatsRank";
import { useAppDispatch, useAppSelector } from "../hooks";
import { track } from "../app/trackingSlice";
import StatsPanel from "./StatsPanel";
import { logInfo } from "../helpers/tracing";

const Stats = () => {
  const dispatch = useAppDispatch();

  logInfo({ message: "Rendering Stats" });

  const stats = useAppSelector(selectStats);
  const gameStatus = useAppSelector(selectGameStatus);
  const puzzle = useAppSelector(selectPuzzle);
  const { link } = puzzle || {};

  const trackingParameters: string = link?.url.includes("?")
    ? ""
    : `?utm_source=hangfive&utm_campaign=end-link-${puzzle.date}`;

  const gameEnded = gameStatus !== GameStatus.IN_PROGRESS;

  const isFirstGame = stats?.totalGames === 1;

  return (
    <div className="text-center">
      <StatsTitle className="font-bold text-lg mb-1.5" />
      {gameEnded && <StatsTimer />}
      <StatsIntro />
      <StatsRank />
      {gameEnded && link && (
        <p className="font-bold my-2 ">
          <a
            onClick={() =>
              dispatch(
                track({
                  eventName: "click_external_link",
                  properties: {
                    puzzleDate: puzzle?.date,
                    targetUrl: link.url,
                  },
                })
              )
            }
            target="_blank"
            className="hover:underline text-violet-600 dark:text-violet-400"
            href={`${link.url}${trackingParameters}`}
          >
            {link.text}
          </a>
        </p>
      )}
      {gameEnded && <ShareButton className="my-3 w-full" />}
      {/* <div className="-mx-6">
          <AdUnit id="stats-banner" type={AdUnitType.MobileBanner} />
        </div> */}
      <h2 className="text-left font-bold text my-2">Your stats</h2>
      {stats && (
        <div className="relative">
          <StatsPanel obfuscate={isFirstGame} />
          {isFirstGame && (
            <div className="absolute top-0 w-full h-full text-center py-3 px-3">
              <div className="block align-middle h-full">
                <p className="text-lg font-bold mb-1">
                  👋 Welcome to Hang Five!
                </p>
                <p className="text-lg">Thanks for playing.</p>
                <p className="text-lg font-bold mb-2">
                  Play tomorrow to continue your streak.
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Stats;
