import { postNativeBridgeMessage } from "../helpers/nativeBridge";
import { startAppListening } from "./listenerMiddleware"
import { goBack, locationChanged } from "./navigationSlice";

export const registerNavigationListener = () => {
  startAppListening({
    actionCreator: goBack,
    effect: async () => {
      window.history.back()
    }
  });

  startAppListening({
    actionCreator: locationChanged,
    effect: async (action) => {
      console.log("Changing location to", action.payload);

      postNativeBridgeMessage("locationChanged", action.payload);
    }
  });
};