import { useState, useEffect } from "react";
import { getSecondsUntilDate } from "../helpers/dates";
import Time from "./Time";
import { TimeStyle } from "../types/TimeStyle";

type Props = {
  className?: string;
  targetDate: Date;
  onTimerEnd: () => void;
};

const Countdown = ({ className = "", targetDate, onTimerEnd }: Props) => {
  const [timeLeft, setTimeLeft] = useState(getSecondsUntilDate(targetDate));

  useEffect(() => {
    const countdownTimer = setInterval(() => {
      const timeLeft = getSecondsUntilDate(targetDate);

      if (timeLeft <= 0) {
        clearInterval(countdownTimer);
        onTimerEnd();
      } else {
        setTimeLeft(timeLeft);
      }
    }, 1000);
  }, [targetDate, onTimerEnd]);

  return (
    <p className={`text-sm ${className}`}>
      Come back tomorrow in{" "}
      <strong className="font-bold tabular-nums">
        <Time seconds={timeLeft} style={TimeStyle.HOURS} />
      </strong>
    </p>
  );
};

export default Countdown;
