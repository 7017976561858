import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { readSettingsFromLocalStorage } from "../helpers/persistence";

type SettingsState = {
  hasSeenInfo: boolean;
  hasMigrated: boolean;
};

export const settingsSlice = createSlice({
  initialState: readSettingsFromLocalStorage(),
  name: "settings",
  reducers: {
    setHasMigrated: (state: SettingsState) => {
      state.hasMigrated = true;
    },
    setHasSeenInfo: (state: SettingsState) => {
      state.hasSeenInfo = true;
    },
  },
});

export const selectHasSeenInfo = (state: RootState) =>
  state.settings.hasSeenInfo;

export const selectHasMigrated = (state: RootState) =>
  state.settings.hasMigrated;

export const { setHasSeenInfo, setHasMigrated } = settingsSlice.actions;
export default settingsSlice.reducer;
export type { SettingsState };
