import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface ToastState {
  messages: string[];
}

const initialState: ToastState = {
  messages: [],
};

interface ShowToastPayload {
  message: string;
}

export const toastSlice = createSlice({
  initialState,
  name: "toast",
  reducers: {
    clearToasts: (state) => {
      state.messages = [];
    },
    dismissToast: (state) => {
      state.messages = state.messages.slice(1);
    },
    showToast: (state, action: PayloadAction<ShowToastPayload>) => {
      const { message } = action.payload;
      state.messages = [...state.messages, message];
    },
  },
});

export const selectToastMessages = (state: RootState) => state.toast.messages;

export const { clearToasts, showToast, dismissToast } = toastSlice.actions;
export default toastSlice.reducer;
export type { ToastState };
