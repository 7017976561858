import Modal from "./Modal";

import { useAppDispatch } from "../hooks";
import { track } from "../app/trackingSlice";

import { hideModal } from "../app/modalSlice";
import Stats from "./Stats";

const StatsModal = () => {
  const dispatch = useAppDispatch();

  const onCloseClick = () => {
    dispatch(track({ eventName: "stats_closed" }));
    dispatch(hideModal());
  };

  return (
    <Modal
      buttonText="Close"
      buttonVariant="secondary"
      onCloseClick={onCloseClick}
    >
      <Stats />
    </Modal>
  );
};

export default StatsModal;
