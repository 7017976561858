import { useEffect } from "react";
import Gameplay from "./Gameplay";
import { loadTodaysGame } from "../helpers/puzzles";
import { useAppDispatch, useAppSelector } from "../hooks";
import { loadPersistedGameState, selectGameIndex } from "../app/gameSlice";

export const DailyGame = () => {
  const dispatch = useAppDispatch();
  const gameIndex = useAppSelector(selectGameIndex);

  useEffect(() => {
    loadTodaysGame(dispatch);
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadPersistedGameState({ gameIndex }));
  }, [dispatch, gameIndex]);

  return <Gameplay />;
};
