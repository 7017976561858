import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

// Define the enum for modal types
export enum ModalType {
  Info = "INFO",
  Stats = "STATS",
  UseNative = "USE_NATIVE",
}

// Define the initial state
interface ModalState {
  showModal: boolean;
  modalType: ModalType | null;
}

const initialState: ModalState = {
  modalType: null,
  showModal: false,
};

// Create the slice
const modalSlice = createSlice({
  initialState,
  name: "modal",
  reducers: {
    hideModal: (state) => {
      state.showModal = false;
      state.modalType = null;
    },
    showModal: (state, action: PayloadAction<ModalType>) => {
      state.showModal = true;
      state.modalType = action.payload;
    },
  },
});

// Export the actions
export const { showModal, hideModal } = modalSlice.actions;

export const selectModalType = (state: RootState) => state.modal.modalType;

// Export the reducer
export default modalSlice.reducer;
