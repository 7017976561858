import { selectStats } from "../app/gameSlice";
import { useAppSelector } from "../hooks";
import { useMessage } from "../hooks/useMessage";
import { StatPanel } from "./stats/StatPanel";

type Props = {
  obfuscate: boolean;
};

const StatsPanel = ({ obfuscate }: Props) => {
  const stats = useAppSelector(selectStats);

  const played = useMessage("played_stat");
  const winStreak = useMessage("win_streak_stat");
  const playStreak = useMessage("play_streak_stat");
  const wonStat = useMessage("won_stat");
  const longestWinStreak = useMessage("longest_win_streak_stat");
  const longestPlayStreak = useMessage("longest_play_streak_stat");

  return (
    <div className="border border-slate-200 rounded-lg py-3 px-2">
      <div className={`${obfuscate ? "blur-sm" : ""} grid grid-cols-3 gap-4 `}>
        <StatPanel label={played.body} value={stats?.totalGames.toString()} />
        <StatPanel
          label={winStreak.body}
          value={stats?.currentWinStreak.toString()}
        />
        <StatPanel
          label={playStreak.body}
          value={stats?.currentPlayStreak.toString()}
        />
        <StatPanel
          label={wonStat.body}
          value={`${stats?.winPercentage.toFixed(0)}%`}
        />
        <StatPanel
          label={longestWinStreak.body}
          value={stats?.maxWinStreak.toString()}
        />
        <StatPanel
          label={longestPlayStreak.body}
          value={stats?.maxPlayStreak.toString()}
        />
      </div>
    </div>
  );
};

export default StatsPanel;
