import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the initial state
interface PageSliceState {
  title?: string;
  gameIndex?: number;
  previousTitle?: string;
  previousGameIndex?: number;
}

const initialState: PageSliceState = {
  gameIndex: undefined,
  title: "",
};

// Create the slice
const pageSlice = createSlice({
  initialState,
  name: "page",
  reducers: {
    clearTitle: (state) => {
      state.title = state.previousTitle;
      state.gameIndex = state.previousGameIndex;
    },
    setTitle: (
      state,
      action: PayloadAction<{ title: string; gameIndex?: number }>
    ) => {
      state.previousTitle = state.title;
      state.previousGameIndex = state.gameIndex;

      const { title, gameIndex } = action.payload;
      state.title = title;
      state.gameIndex = gameIndex;
    },
  },
});

export const selectPageTitle = createSelector(
  (state: { page: PageSliceState }) => state.page,
  (page) => {
    const suffix = page.title ? ` - ${page.title}` : "";

    return page.gameIndex ? `#${page.gameIndex}${suffix}` : page.title;
  }
);

export const { clearTitle, setTitle } = pageSlice.actions;

export default pageSlice.reducer;
