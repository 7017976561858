import { useEffect } from "react";
import { AdUnitType } from "../types/AdUnitType";

const debugClassNames = ""; // "text-center border border-slate-300";

const getSizeClassNames = (type: AdUnitType) => {
  switch (type) {
    case AdUnitType.MobileBanner:
      return "w-[320px] h-[50px]";
    case AdUnitType.WideSkyscraper:
      return "w-[160px] h-[600px]";
    case AdUnitType.MediumRectangle:
      return "w-[300px] h-[250px]";
  }
};

const getWidth = (type: AdUnitType) => {
  switch (type) {
    case AdUnitType.MobileBanner:
      return "320";
    case AdUnitType.WideSkyscraper:
      return "160";
    case AdUnitType.MediumRectangle:
      return "300";
  }
};

const getHeight = (type: AdUnitType) => {
  switch (type) {
    case AdUnitType.MobileBanner:
      return "50";
    case AdUnitType.WideSkyscraper:
      return "600";
    case AdUnitType.MediumRectangle:
      return "250";
  }
};

type Props = {
  type: AdUnitType;
  className?: string;
  id: string;
  reportPosition:
    | "bottom-right-side"
    | "bottom-left-side"
    | "bottom-left"
    | "bottom-right"
    | "bottom-center";
};

const AdUnit = ({
  type = AdUnitType.MobileBanner,
  className = "",
  id,
  reportPosition = "bottom-right-side",
}: Props) => {
  useEffect(() => {
    const ad = window["nitroAds"].createAd(id, {
      // mediaQuery: "(min-width: 320px) and (max-width: 767px)",
      demo: import.meta.env.MODE !== "production",
      refreshTime: 30,
      renderVisibleOnly: false,
      report: {
        enabled: false,
        icon: true,
        position: reportPosition,
        wording: "Report Ad",
      },
      sizes: [[getWidth(type), getHeight(type)]],
    });

    console.log("Created ad", ad);
  }, [id, reportPosition, type]);

  return (
    <div
      id={id}
      className={`mx-auto ${getSizeClassNames(
        type
      )} ${debugClassNames} ${className}`}
    ></div>
  );

  return null;
};

export default AdUnit;
