import { useSelector } from "react-redux";
import {
  selectGameIndex,
  selectGameStatus,
  selectStats,
} from "../../app/gameSlice";
import GameStatus from "../../types/GameState";
import { useMessage } from "../../hooks/useMessage";
import { logInfo } from "../../helpers/tracing";

export const StatsIntro = ({ className }: { className?: string }) => {
  logInfo({ message: "Rendering StatsIntro" });

  const status = useSelector(selectGameStatus);
  const gameIndex = useSelector(selectGameIndex);
  const stats = useSelector(selectStats);

  const isFirstGame = stats?.totalGames === 1;

  const puzzleInProgress = useMessage("stats_intro.puzzle_in_progress", {
    gameIndex,
  });
  const solvedFirstGame = useMessage("stats_intro.solved_first_game");
  const solved = useMessage("stats_intro.solved");
  const lostFirstGame = useMessage("stats_intro.lost_first_game");
  const lost = useMessage("stats_intro.lost");

  let message: string;

  switch (status) {
    case GameStatus.IN_PROGRESS:
      message = puzzleInProgress.body;
      break;
    case GameStatus.SOLVED:
      if (isFirstGame) {
        message = solvedFirstGame.body;
      } else {
        message = solved.body;
      }
      break;
    case GameStatus.LOST:
      if (isFirstGame) {
        message = lostFirstGame.body;
      } else {
        message = lost.body;
      }
      break;
  }

  return <p className={className}>{message}</p>;
};
