import { TimeStyle } from "../types/TimeStyle";

type Props = {
  seconds: number;
  style: TimeStyle;
};

// Returns seconds in hh:mm:ss format
const Time = ({ seconds, style = TimeStyle.HOURS }: Props) => {
  if (style === TimeStyle.HOURS) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsLeft = seconds % 60;

    const hoursString = hours.toString().padStart(2, "0");
    const minutesString = minutes.toString().padStart(2, "0");
    const secondsString = Math.floor(secondsLeft).toString().padStart(2, "0");

    return (
      <>
        {hoursString}:{minutesString}:{secondsString}
      </>
    );
  } else if (style === TimeStyle.MINUTES) {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;

    const minutesString = minutes.toString().padStart(2, "0");
    const secondsString = Math.floor(secondsLeft).toString().padStart(2, "0");

    return (
      <>
        {minutesString}:{secondsString}
      </>
    );
  } else if (style === TimeStyle.MINUTES_WORDS) {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;

    const minutesString = minutes.toString();
    const secondsString = Math.floor(secondsLeft).toString();

    if (minutes === 0) {
      return (
        <>
          {secondsString} second{secondsLeft === 1 ? "" : "s"}
        </>
      );
    }

    return (
      <>
        {minutesString} minute{minutes === 1 ? "" : "s"} and {secondsString}{" "}
        second
        {secondsLeft === 1 ? "" : "s"}
      </>
    );
  } else if (style === TimeStyle.SECONDS) {
    return <>{Math.floor(seconds)}</>;
  }
};

export default Time;
