import React, { ReactElement, useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Diagnostics from "./components/Diagnostics.tsx";

import NotFound from "./components/NotFound.tsx";
import { Provider } from "react-redux";
import { store } from "./app/store.ts";
import StartScreen from "./components/StartScreen.tsx";
import App from "./App.tsx";
import { DailyGame } from "./components/DailyGame.tsx";
import { ArchiveGame } from "./components/ArchiveGame.tsx";
import { TrainingGame } from "./components/TrainingGame.tsx";
import StatsScreen from "./components/StatsScreen.tsx";
import HowToPlayScreen from "./components/HowToPlayScreen.tsx";
import { MigrateToNative } from "./components/MigrateToNative.tsx";
import { MIGRATE_TO_NATIVE_PATH } from "./constants/paths.ts";
import { initializeTracking } from "./helpers/tracking.ts";
import IOSMarketing from "./components/IOSMarketing.tsx";

const newRoutes = [
  {
    children: [
      {
        element: <StartScreen />,
        path: "",
      },
      {
        element: <DailyGame />,
        path: "play",
      },
      {
        element: <ArchiveGame />,
        path: "archive/:id",
      },
      {
        element: <StatsScreen />,
        path: "stats",
      },
      {
        element: <TrainingGame />,
        path: "learn/:id",
      },
      {
        element: <HowToPlayScreen />,
        path: "how-to-play",
      },
      {
        element: <Diagnostics />,
        path: "about",
      },
      {
        element: <IOSMarketing />,
        path: "ios",
      },
    ],
    element: <App />,
    path: "",
  },
  {
    element: <MigrateToNative />,
    path: `${MIGRATE_TO_NATIVE_PATH}/:platform`,
  },
];

const renderRouter = (): ReactElement => {
  const router = createBrowserRouter([
    ...newRoutes,
    {
      element: <NotFound />,
      path: "*",
    },
  ]);

  return <RouterProvider router={router} />;
};

export const Root = () => {
  useEffect(() => {
    initializeTracking();
  }, []);

  return (
    <React.StrictMode>
      <Provider store={store}>{renderRouter()}</Provider>
    </React.StrictMode>
  );
};
